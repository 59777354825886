import http from '@/utils/http.js'

// 邮箱域名查询
export function domainData(data) {
    return http({
        url: "/jasoboss/tool/email/get",
        method: "post",
        data
    })
}

// 邮箱扩容计算器
export function calculation(data) {
    return http({
        url: "/jasoboss/tool/email/calculation",
        method: "post",
        data
    })
}


// 邮箱渠道计算器
export function calculationChannel(data) {
    return http({
        url: "/jasoboss/tool/email/calculation/channel",
        method: "post",
        data
    })
}



// 邮箱 腾讯  （2023-01-06）官网算法
export function calculationNew(data) {
    return http({
        url: "/jasoboss/tool/email/calculation/new",
        method: "post",
        data
    })
}